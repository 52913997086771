
















































































































































































































































































































@import '~@/assets/styles/components/table';
.v-data-table thead tr th,
.v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
  color: $colorPrimary !important;
}

.box-table {
  // border-top: 5px solid $colorPrimary;
  // border-top-left-radius: 0 !important;
  // border-top-right-radius: 0 !important;

  .v-data-table-header-mobile {
    display: none;
  }

  &.box-table-gecom {
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > th {
      height: auto;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}

.fab-cadastrar-usuario {
  position: fixed;
  z-index: 1;
  bottom: 25px;
  right: 5px;

  @media (min-width: 599px) {
    display: none !important;
  }
}
